import React from "react";
import { useInView } from "react-intersection-observer";
import {
  ContactWrapper,
  Heading,
  Text,
  TextContainer,
  SocialsContainer,
  Social,
} from "./styled.js";

function Contact(props) {
  const { id } = props;
  const { ref, inView } = useInView({
    threshold: 0.1, // Adjust the threshold as needed
  });
  return (
    <ContactWrapper id={id} ref={ref}>
      <Heading className={inView && "test"}>Contact</Heading>
      <TextContainer>
        <Text>
          Please feel free to contact me via some of the following socials
        </Text>
      </TextContainer>
      <SocialsContainer>
        <a href="https://github.com/luudviig">
          <Social className="fa-brands fa-github" />
        </a>
        <a href="https://www.linkedin.com/in/ludvig-j%C3%B6nsson-483003156/">
          <Social className="fa-brands fa-linkedin" />
        </a>
        <a href="mailto:ludvigjonsson28@gmail.com">
          <Social className="fa-solid fa-envelope" />
        </a>
      </SocialsContainer>
    </ContactWrapper>
  );
}

export default Contact;
