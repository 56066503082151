import React, { useState, useEffect } from "react";
import Hexagon from "./Hexagon";
import { Column, OuterWrapper, Row2 } from "./Hexagon/styled";

function PixelBackground() {
  const [boxRow, setBoxRow] = useState();
  const [rowAmount, setAmountRows] = useState([]);
  const [boxSize, setBoxSize] = useState(125);

  useEffect(() => {
    const updateBoxCounts = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      let newBoxSize = boxSize;

      if (width <= 900 && boxSize > 70) {
        newBoxSize = boxSize * 0.6;
      }

      // Calculate the number of boxes that fit horizontally and vertically
      const boxesPerRow = Math.floor(width / newBoxSize) + 2;
      const boxesPerColumn = Math.floor(
        height / newBoxSize + newBoxSize * 0.75
      );

      const amountRows = Array(boxesPerColumn).fill({});

      setBoxRow(boxesPerRow);
      setAmountRows(amountRows);
      setBoxSize(newBoxSize);
    };

    // Initial calculation
    updateBoxCounts();

    window.addEventListener("resize", updateBoxCounts);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateBoxCounts);
    };
  }, []);

  let counter = -2;

  const Hexas = ({ pushleft = false, counter }) => {
    let Comp;
    if (pushleft) {
      Comp = (
        <Row2
          pushtop={`${counter * boxSize * 0.75}px`}
          pushleft={`-${boxSize / 2}px`}
        >
          {Array(boxRow)
            .fill({})
            .map((e, i) => (
              <Hexagon key={i} size={boxSize} />
            ))}
        </Row2>
      );
    } else {
      Comp = (
        <Row2
          pushtop={`${counter * boxSize * 0.75}px`}
          pushleft={`-${boxSize}px`}
        >
          {Array(boxRow)
            .fill({})
            .map((e, i) => (
              <Hexagon key={i} size={boxSize} />
            ))}
        </Row2>
      );
    }
    return Comp;
  };

  return (
    <OuterWrapper>
      <Column>
        {rowAmount.map((e, i) => {
          let Comp;
          counter++;

          if (i % 2 == 0) {
            Comp = <Hexas key={i} pushleft={true} counter={counter} />;
            return Comp;
          } else {
            Comp = <Hexas key={i} pushleft={false} counter={counter} />;
          }
          return Comp;
        })}
      </Column>
    </OuterWrapper>
  );
}

export default PixelBackground;
