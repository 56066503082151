import styled from "styled-components";
import { device } from "../../../breakpoints";

export const HomeWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: start;
  background-color: black;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

export const Text = styled.h1`
  color: #fafafa;
  text-align: center;
  font-weight: 700;
  width: 100%;
  z-index: 50;
`;

export const Cursor = styled.em`
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  color: #fafafa;
  margin-left: 16px;
  font-size: 24px;
  animation: blink 1s linear infinite;
`;

export const ColoredText = styled.span`
  color: rgb(12, 231, 121);
  font-weight: 600;
  font-style: italic;
`;

export const CompleteWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${device.md} {
    width: 100%;
  }
`;
