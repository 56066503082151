import React, { useEffect, useRef, useState } from "react";
import {
  CardWrapper,
  Card as CardStyle,
  CardHeader,
  CardBody,
  CardTimeLine,
  CardDescriptions,
  CardTitle,
  CardDescription,
  CardLogo,
  CardImg,
  DescriptionsContainer,
  DesContainer,
  CardFooter,
  Skill,
} from "./styled";

import { useInView } from "react-intersection-observer";

function Card(props) {
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const { ref, inView } = useInView({
    threshold: 0.1, // Adjust the threshold as needed
  });
  const [animationClass, setAnimationClass] = useState("");
  const {
    imgSrc,
    descriptions = [],
    background,
    title,
    yearFrom = "",
    yearTo = "",
    current = false,
    skills = [],
    key,
  } = props;

  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;
    if (container && content && content.scrollHeight > container.clientHeight) {
      // Apply the scroll-down animation
      setAnimationClass("scroll-down");

      // After the scroll-down animation completes, start the scroll-up animation
      const timeoutId = setTimeout(() => {
        setAnimationClass("scroll-up");
      }, 700); // Duration of the scroll-down animation

      // Clean up the timeout on component unmount
      return () => clearTimeout(timeoutId);
    }
  }, [inView]);

  return (
    <CardWrapper key={`${title}_${yearFrom}_Card`}>
      <CardStyle>
        <CardHeader background={background}>
          <CardLogo background={background}>
            <CardImg src={imgSrc} />
          </CardLogo>
        </CardHeader>
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <CardTimeLine>{`${yearFrom} - ${
            current ? "Nu" : yearTo
          }`}</CardTimeLine>
          <div style={{ height: "100%", overflowY: "auto" }} ref={ref}>
            {
              <DesContainer ref={containerRef}>
                <CardDescriptions ref={contentRef} className={animationClass}>
                  {descriptions.map((d, i) => (
                    <CardDescription key={`Description${i}`}>
                      {d}
                    </CardDescription>
                  ))}
                </CardDescriptions>
              </DesContainer>
            }
          </div>
        </CardBody>
        <CardFooter>
          {skills.map((skill, i) => (
            <Skill key={`Skill_${i}`}>{skill}</Skill>
          ))}
        </CardFooter>
      </CardStyle>
    </CardWrapper>
  );
}

export default Card;
