import React from "react";
import { ProfileImageWrapper, Image } from "./styled";
function ProfileImage(props) {
  return (
    <ProfileImageWrapper>
      <Image src="me.png" />
    </ProfileImageWrapper>
  );
}

export default ProfileImage;
