import { useState, useEffect } from "react";
import { Text } from "./styled";

const Typewriter = ({
  texts = [],
  delay,
  infinite,
  fontweight,
  fontsize,
  inlineblock,
}) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentTextArrIndex, setCurrentTextArrIndex] = useState(0);

  /*
  useEffect(() => {
    let timeout;
    timeout = setTimeout(() => {
      if (currentIndex < texts[currentTextArrIndex].length) {
        if (texts[currentTextArrIndex][currentIndex]) {
          setCurrentText(
            (prevText) => prevText + texts[currentTextArrIndex][currentIndex]
          );
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }
      } else if (infinite) {
        // ADD THIS CHECK
        setCurrentIndex(0);
        setCurrentText("");
        if (currentTextArrIndex >= texts.length - 1) {
          setCurrentTextArrIndex(0);
        } else {
          setCurrentTextArrIndex((prev) => prev + 1);
        }
      }
    }, delay);

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, infinite, texts, currentTextArrIndex]);
  */

  useEffect(() => {
    let timeout;
    timeout = setTimeout(() => {
      if (currentIndex < texts[currentTextArrIndex].length) {
        if (texts[currentTextArrIndex][currentIndex]) {
          setCurrentText(
            (prevText) => prevText + texts[currentTextArrIndex][currentIndex]
          );
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }
      } else if (infinite) {
        // ADD THIS CHECK
        setCurrentIndex(0);
        setCurrentText("");
        if (currentTextArrIndex >= texts.length - 1) {
          setCurrentTextArrIndex(0);
        } else {
          setCurrentTextArrIndex((prev) => prev + 1);
        }
      }
    }, delay);

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, infinite, texts, currentTextArrIndex]);

  return (
    <Text inlineblock={inlineblock} fontweight={fontweight} fontsize={fontsize}>
      {currentText}
    </Text>
  );
};

export default Typewriter;
