import React from "react";
import { Hexagon as Hex, HexagonShadowTop, HexagonShadowLeft } from "./styled";

function Hexagon({ size = "50px" }) {
  return (
    <Hex size={size}>
      <HexagonShadowTop size={size} />
      <HexagonShadowLeft size={size} />
    </Hex>
  );
}

export default Hexagon;
