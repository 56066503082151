import React from "react";
import {
  AboutWrapper,
  LevelsColumn,
  TextColumn,
  TextItem,
  Text,
  TextContainer,
  Heading,
  Wrapper,
} from "./styled";
import ProgressBar from "../../progressBar";
import Typewriter from "../../typeWriter";
import { useInView } from "react-intersection-observer";

function About(props) {
  const { id } = props;
  const { ref, inView } = useInView({
    threshold: 0.1, // Adjust the threshold as needed
  });

  return (
    <AboutWrapper id={id} ref={ref}>
      <Heading className={inView && "test"}>About</Heading>
      <Wrapper>
        <TextColumn>
          <TextContainer>
            <Text bold={"true"}>Who am I?</Text>
          </TextContainer>

          <TextContainer>
            <Text>My name is Ludvig.</Text>
          </TextContainer>

          <TextContainer>
            <Text>
              ⚡ I am a fullstack-developer with experience with tech such as
              React, Node, Docker, Kubernetes, and more.
            </Text>
          </TextContainer>

          <TextContainer>
            <Text>
              ⚡ Since 2018 I have been passionately learning and exploring new
              technologies that have helped me become a better developer.
            </Text>
          </TextContainer>

          <TextContainer>
            <Text>
              {"⚡ I love "}
              <Typewriter
                texts={[
                  "to solve complex problems.",
                  "to meet new people.",
                  "to learn new technologies. ",
                ]}
                delay={175}
                fontsize="1.1rem"
                fontweight="300"
                infinite={true}
                inlineblock={true}
              />
            </Text>
          </TextContainer>
        </TextColumn>

        <LevelsColumn>
          <TextContainer>
            <Text bold={"true"}>Kompetenser</Text>
          </TextContainer>
          <ProgressBar
            label={"Javascript"}
            backgroundcolor={"#3e4555"}
            visualParts={[
              {
                percentage: "85%",
                color: "#0ce779",
              },
            ]}
          />

          <ProgressBar
            label={"React"}
            backgroundcolor={"#3e4555"}
            visualParts={[
              {
                percentage: "80%",
                color: "#0ce779",
              },
            ]}
          />
          <ProgressBar
            label={"Spring boot"}
            backgroundcolor={"#3e4555"}
            visualParts={[
              {
                percentage: "50%",
                color: "#0ce779",
              },
            ]}
          />
          <ProgressBar
            label={"Java"}
            backgroundcolor={"#3e4555"}
            visualParts={[
              {
                percentage: "60%",
                color: "#0ce779",
              },
            ]}
          />
          <ProgressBar
            label={"Kubernetes"}
            backgroundcolor={"#3e4555"}
            visualParts={[
              {
                percentage: "60%",
                color: "#0ce779",
              },
            ]}
          />
          <ProgressBar
            label={"Docker"}
            backgroundcolor={"#3e4555"}
            visualParts={[
              {
                percentage: "70%",
                color: "#0ce779",
              },
            ]}
          />
          <ProgressBar
            label={"Mongodb"}
            backgroundcolor={"#3e4555"}
            visualParts={[
              {
                percentage: "70%",
                color: "#0ce779",
              },
            ]}
          />
          <ProgressBar
            label={"Mysql"}
            backgroundcolor={"#3e4555"}
            visualParts={[
              {
                percentage: "60%",
                color: "#0ce779",
              },
            ]}
          />
          <ProgressBar
            label={"CI/CD"}
            backgroundcolor={"#3e4555"}
            visualParts={[
              {
                percentage: "75%",
                color: "#0ce779",
              },
            ]}
          />
          <ProgressBar
            label={"Linux"}
            backgroundcolor={"#3e4555"}
            visualParts={[
              {
                percentage: "70%",
                color: "#0ce779",
              },
            ]}
          />
        </LevelsColumn>
      </Wrapper>
    </AboutWrapper>
  );
}

export default About;
