import React, { Suspense, useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
const ProgressBar = React.lazy(() => import("./ProgressBar"));
//import ProgressBar from "./ProgressBar";

function Index(props) {
  const { ref, inView } = useInView({
    threshold: 0.1, // Adjust the threshold as needed
  });
  const [hasBeenInView, setHasBeenInView] = useState(false);

  useEffect(() => {
    if (inView) {
      setHasBeenInView(true);
    }
  }, [inView]);

  return (
    <div style={{ width: "100%" }} ref={ref}>
      {(inView || hasBeenInView) && (
        <Suspense>
          <ProgressBar {...props} />
        </Suspense>
      )}
    </div>
  );
}

export default Index;
