import styled from "styled-components";
import { device } from "../../../breakpoints";

export const ExperiencesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Experiences = styled.div`
  display: flex;
  padding: 2.5rem 5rem;
  justify-content: center;
  flex-wrap: wrap;
  transition: all 2s;
  width: 100%;

  @media ${device.md} {
    flex-wrap: nowrap;
    padding: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Heading = styled.h1`
  position: relative;
  color: #fafafa;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  margin-top: 5rem;
  padding-bottom: 12px;
  transform: translate(-50%, 0);
  left: 50%;
  width: min-content;

  &:after {
    content: "";
    position: absolute;
    display: block;
    height: 5px;
    bottom: 0;
    width: 100%;
    background-color: rgb(12, 231, 121);
    transform: scaleX(0);
    transition: transform 2s ease;
  }

  &.test:after {
    transform: scaleX(1);
  }
`;
