import React from "react";
import {
  ColoredText,
  CompleteWrapper,
  Cursor,
  HomeWrapper,
  Text,
} from "./styled";

import ProfileImage from "../../profileImage";
import Typewriter from "../../typeWriter";
import PixelBackground from "../../PixelBackground";

function Home(props) {
  return (
    <HomeWrapper>
      <PixelBackground />
      <CompleteWrapper>
        <ProfileImage />
        <Text>
          Hello, I'm <ColoredText>Ludvig Jönsson Torcelino</ColoredText>. 👋
          <br />
          <Typewriter
            texts={[
              "I like to create unique experiences.",
              "I like to learn new stuff.",
              "If you have any questions or fun projects to collaborate on, please contact me.",
            ]}
            delay={150}
            infinite={true}
          />
          <Cursor className="fa fa-terminal" />
        </Text>
      </CompleteWrapper>
    </HomeWrapper>
  );
}

export default Home;
