import React from "react";
import { NavItem, NavText, NavWrapper } from "./styled";

function Navbar(props) {
  const toAbout = () => {
    const element = document.getElementById("aboutSection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const toContact = () => {
    const element = document.getElementById("contactSection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toExp = () => {
    const element = document.getElementById("expSection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <NavWrapper>
      <NavItem onClick={toAbout}>
        <NavText>About</NavText>
      </NavItem>
      <NavItem onClick={toExp}>
        <NavText>Experiences</NavText>
      </NavItem>
      <NavItem onClick={toContact}>
        <NavText>Contact</NavText>
      </NavItem>
    </NavWrapper>
  );
}

export default Navbar;
