import styled from "styled-components";

export const Hexagon = styled.div`
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  height: ${(p) => (p.size ? p.size + "px" : "50px")};
  width: ${(p) => (p.size ? p.size + "px" : "50px")};
  background-color: ${(p) => (p.background ? p.background : "rgb(44,50,64)")};
  position: relative;
  transition: transform 0.5s linear;
  &:hover {
    transform: scale(1.35);
    z-index: 999;
    background-color: rgb(62, 69, 85);
    cursor: pointer;
  }
`;

export const HexagonShadowTop = styled.div`
  height: ${(p) => (p.size ? p.size + "px" : "50px")};
  width: ${(p) => (p.size ? p.size + "px" : "50px")};
  clip-path: polygon(0% 25%, 50% 50%, 100% 25%, 50% 0);
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
`;

export const HexagonShadowLeft = styled.div`
  height: ${(p) => (p.size ? p.size + "px" : "50px")};
  width: ${(p) => (p.size ? p.size + "px" : "50px")};
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 50% 50%);
  position: absolute;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 50px); /* Adjust the number of columns */
  grid-template-rows: repeat(4, 50px); /* Adjust the number of rows */
  gap: 0;
`;

export const Row = styled.div`
  display: flex;
  position: relative;
`;

export const Row2 = styled.div`
  display: flex;
  left: ${(p) => (p.pushleft ? p.pushleft : "0px")};
  top: ${(p) => (p.pushtop ? p.pushtop : "-25px")};
  position: absolute;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const OuterWrapper = styled.div`
  width: 100%;
`;
