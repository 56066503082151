import styled from "styled-components";

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.h1`
  position: relative;
  color: #fafafa;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  margin-top: 5rem;
  padding-bottom: 12px;
  transform: translate(-50%, 0);
  left: 50%;
  width: min-content;

  &:after {
    content: "";
    position: absolute;
    display: block;
    height: 5px;
    bottom: 0;
    width: 100%;
    background-color: rgb(12, 231, 121);
    transform: scaleX(0);
    transition: transform 2s ease;
  }

  &.test:after {
    transform: scaleX(1);
  }
`;

export const TextContainer = styled.div`
  padding: 2.5rem 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span`
  font-size: 1.1rem;
  color: #fafafa;
  text-align: center;
`;

export const SocialsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 5rem 2.5rem 5rem;
`;

export const Social = styled.em`
  font-size: 42px;
  color: #fafafa;
  margin: 0px 12px;

  &:hover {
    color: rgb(12, 231, 121);
    cursor: pointer;
  }
`;
