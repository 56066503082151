import React from "react";
import {
  ExperiencesWrapper,
  Experiences as ExperiencesStyle,
  Heading,
} from "./styled";
import Card from "../../card";
import { useInView } from "react-intersection-observer";

function Experiences(props) {
  const { id } = props;
  const { ref, inView } = useInView({
    threshold: 0.1, // Adjust the threshold as needed
  });
  return (
    <ExperiencesWrapper id={id} ref={ref}>
      <Heading className={inView && "test"}>Experiences</Heading>

      <ExperiencesStyle>
        <Card
          title={"Fullstack-utvecklare"}
          yearFrom={2021}
          yearTo={2024}
          current={true}
          imgSrc="/alfa.png"
          background={"linear-gradient(rgb(10, 50, 100),rgb(0,100, 200))"}
          descriptions={[
            "Product development, consulting, thirdline-support",
            "Responsible for product used by multiple technicians on a daily basis, high demand on reliability, scalability, and offlinemode.",
            "Payment solutions",
          ]}
          skills={["React", "Node", "Devops", "MongoDB"]}
        />
        <Card
          title={"Fullstack-utvecklare"}
          yearFrom={2021}
          yearTo={2024}
          current={true}
          imgSrc="/spLogo.png"
          background={"linear-gradient(rgb(64, 117, 150), rgb(100, 150, 200))"}
          descriptions={[
            "Product development, consulting, thirdline-support",
            "Payment solutions",
            "Queue systems",
            "Lowcode functionality",
          ]}
          skills={["React", "Node", "Devops", "MongoDB"]}
        />

        <Card
          title={"Student"}
          yearFrom={2018}
          yearTo={2021}
          imgSrc="/hkr.png"
          background={"linear-gradient(rgb(255,255,255),rgb(80,200,100))"}
          descriptions={[
            "Student at University of Kristianstad (Bachelors degree in Computer Science)",
            "Master thesis done at Ramboll in Malmö",
          ]}
          skills={["Java", "PM", "Algorithms/Datastructures"]}
        />
      </ExperiencesStyle>
    </ExperiencesWrapper>
  );
}

export default Experiences;
