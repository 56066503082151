import styled from "styled-components";
import { device } from "../../../breakpoints";

export const AboutWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 2.5rem 5rem;

  @media ${device.md} {
    padding: 12px;
    flex-direction: column;
    align-items: center;
  }
`;

export const Heading = styled.h1`
  position: relative;
  color: #fafafa;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  margin-top: 5rem;
  padding-bottom: 12px;
  transform: translate(-50%, 0);
  left: 50%;
  width: min-content;

  &:after {
    content: "";
    position: absolute;
    display: block;
    height: 5px;
    bottom: 0;
    width: 100%;
    background-color: rgb(12, 231, 121);
    transform: scaleX(0);
    transition: transform 2s ease;
  }

  &.test:after {
    transform: scaleX(1);
  }
`;

export const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0px 5rem;
  width: 50vw;
  max-width: 500px;

  @media ${device.md} {
    padding: 0px;
    width: 100%;
  }
`;

export const Text = styled.span`
  color: #fafafa;
  font-size: ${(p) => (p.bold ? "1.5rem" : "1.1rem")};
  font-weight: ${(p) => (p.bold ? "600" : "300")};
`;
export const TextContainer = styled.div`
  margin-bottom: 12px;
`;

export const LevelsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0px 5rem;
  width: 50vw;
  max-width: 700px;

  @media ${device.md} {
    padding: 0px;
    width: 100%;
  }
`;
