import styled from "styled-components";

export const ProfileImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
`;

export const Image = styled.img`
  width: 220px;
  height: auto;
  border-radius: 50%;
  border: 4px solid rgb(12, 231, 121);
  margin-bottom: 35px;
`;
