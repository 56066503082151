import React, { useRef } from "react";
import { RootWrapper } from "./styled";
import Home from "../buildStones/home";
import About from "../buildStones/about";
import Experiences from "../buildStones/experiences";
import Contact from "../buildStones/contact";
import Navbar from "../buildStones/navbar";

function Root(props) {
  return (
    <RootWrapper>
      <Home />
      <Navbar />
      <About id="aboutSection" />
      <Experiences id="expSection" />
      <Contact id="contactSection" />
    </RootWrapper>
  );
}

export default Root;
