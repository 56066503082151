import styled, { keyframes } from "styled-components";
import { device } from "../../breakpoints";

// Define keyframes for scrolling down and up
const scrollDown = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
`;

const scrollUp = keyframes`
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const CardWrapper = styled.div`
  padding: 12px;
  width: min(385px, 100%);
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 460px;
  width: 100%;
  border-radius: 6px;
  background-color: #3e4555;
  overflow: hidden;

  &:hover {
    transform: scale(1.03);
    transition: transform 0.3s;
    cursor: pointer;
  }
`;

export const CardHeader = styled.div`
  height: 32%;
  //background: linear-gradient(rgb(57, 177, 117), rgb(11, 231, 121));
  background: ${(p) =>
    p.background
      ? p.background
      : "linear-gradient(rgb(57, 177, 117), rgb(11, 231, 121))"};
  position: relative;
`;

export const CardLogo = styled.div`
  position: relative;
  left: 50%;
  top: 100%;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  transform: translate(-50%, -50%);
  background: ${(p) =>
    p.background
      ? p.background
      : "linear-gradient(rgb(57, 177, 117), rgb(11, 231, 121))"};
  display: flex;
  align-items: center;

  box-shadow: 0 0 18px rgba(0,0,0,0.4);
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 55%;
  padding: 65px 0px;
  padding-bottom: 0;
`;

export const CardTitle = styled.h1`
  align-self: center;
  font-weight: 700;
  color: #fafafa;
  font-size: 1.5rem;
`;

export const CardTimeLine = styled.h2`
  align-self: center;
  font-weight: 600;
  color: #fafafa;

  font-size: 1.3rem;
`;

export const CardImg = styled.img`
  position: absolute;
  background-size: contain;
  background-position: center;
  width: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CardDescriptions = styled.ul`
  list-style-type: disc;
  margin-top: 12px;
  list-style-position: default;
  display: flex;
  flex-direction: column;

  &.scroll-down {
    animation: ${scrollDown} 0.5s forwards; /* Scroll down over 2 seconds */
    animation-timing-function: ease-in-out;
  }
  &.scroll-up {
    animation: ${scrollUp} 0.5s forwards; /* Scroll up over 2 seconds */
    animation-timing-function: ease-in-out;
  }
`;

export const CardDescription = styled.li`
  color: #fafafa;
  font-size: 1rem;
`;

export const DesContainer = styled.div`
  display: flex;
  flex-direction: colu @mn;
  padding: 0px 42px;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-scrollbar {
    display: none;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  overflow-x: auto;
  height: 14%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const Skill = styled.div`
  color: #fafafa;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: rgb(94, 106, 128);
  font-weight: 500;
  margin: 0px 3px;
`;
