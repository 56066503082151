import styled from "styled-components";

export const NavWrapper = styled.div`
  display: flex;
  padding: 0rem 0rem;
  align-items: center;
  justify-content: space-around;
  height: 3.5rem;
  background-color: rgb(35, 37, 42);
  border-bottom: 3px solid rgb(12, 231, 121);
`;

export const NavItem = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const NavText = styled.h1`
  color: #fafafa;
  font-size: 1.5rem;
  font-weight: 400;
`;
